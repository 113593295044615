.App-header {
    display: flex;
    flex-direction: row;
    width: 100px;
    text-align: center;
    position: fixed;
    top: 50px;
    right: -67px;
    /* padding-right: 5px; */
    transition: all 0.3s ease;
    z-index: 2000;
}

.App-header.show {
/* .App-header:hover { */
/* .App-header:hover { */
    right: 0px;
}

.App-header.show .visible-header {
    box-shadow: -2px -2px 4px #1a1a1b2e, 2px 2px 4px #0000002e;
    transition: all 0.3s ease;
}

.header-arrow {
    position: absolute;
    top: calc(50% - 12px);
    left: 17px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* transition: all 0.3s ease; */
    opacity: 0.4;
    z-index: 1
}

.header-arrow.hide {
    transition: all 0.3s ease;
    display: none;
}

.visible-header {
    position: relative;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    /* border-radius: 15px; */
    background-color: var(--background-primary-color) !important;
    backdrop-filter: blur(6px);
    box-shadow: -2px -2px 4px #1a1a1b3b, 2px 2px 4px #0000002c;
    display: flex;
    flex-direction: column;
    width: 100px;
    text-align: center;
    /* padding-right: 5px; */
    margin-left: 25px;
    transition: all 0.3s ease;
}

.header-button {
    width: auto;
    margin: 5px;
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 10px;
    color: #e1e1e1;
    text-shadow: 0px 0px 6px #000;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
}

    .header-button:hover {
        box-shadow: inset -2px -2px 4px #7272722e, inset 2px 2px 4px var(--shadow-dark-light);
        transition: all 0.3s ease;
    }


