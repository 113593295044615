.loading-animation {
    display: flex;
    /* justify-content: center;
    align-items: center; */
    height: 100%;
    width: 100%;
    position: absolute;
    top: 20%;
    left: 0;
}

.book-loader {
    width: 60px;
    height: 80px;
    position: relative;
}

.book-loader::before,
.book-loader::after,
.book-loader > div {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid transparent;
    border-radius: 8px;
}

.book-loader::before {
    animation: pulse-1 8s ease-in-out infinite, color-change-1 24s linear infinite;
}

.book-loader::after {
    animation: pulse-2 9s ease-in-out infinite, color-change-2 24s linear infinite;
}

.book-loader > div {
    animation: pulse-3 10s ease-in-out infinite, color-change-3 24s linear infinite;
}

@keyframes pulse-1 {
    0%, 100% { transform: scale(1); border-width: 2px; opacity: 1; }
    25% { transform: scale(0.7); border-width: 4px; opacity: 1; }
    50% { transform: scale(0.05); border-width: 10px; opacity: 0; }
    75% { transform: scale(0.7); border-width: 4px; opacity: 1; }
}

@keyframes pulse-2 {
    0%, 100% { transform: scale(1); border-width: 2px; opacity: 1; }
    25% { transform: scale(0.7); border-width: 4px; opacity: 1; }
    50% { transform: scale(0.05); border-width: 10px; opacity: 0; }
    75% { transform: scale(0.7); border-width: 4px; opacity: 1; }
}

@keyframes pulse-3 {
    0%, 100% { transform: scale(1); border-width: 2px; opacity: 1; }
    25% { transform: scale(0.7); border-width: 4px; opacity: 1; }
    50% { transform: scale(0.05); border-width: 10px; opacity: 0; }
    75% { transform: scale(0.7); border-width: 4px; opacity: 1; }
}

@keyframes color-change-1 {
    0% { border-color: #b83b5e; }  /* Muted raspberry */
    25% { border-color: #6a2c70; } /* Deep purple */
    50% { border-color: #08d9d6; } /* Teal */
    75% { border-color: #ff9a8c; } /* Soft coral */
    100% { border-color: #b83b5e; }/* Muted raspberry */
}

@keyframes color-change-2 {
    0% { border-color: #6a2c70; }  /* Deep purple */
    25% { border-color: #08d9d6; } /* Teal */
    50% { border-color: #ff9a8c; } /* Soft coral */
    75% { border-color: #b83b5e; } /* Muted raspberry */
    100% { border-color: #6a2c70; }/* Deep purple */
}

@keyframes color-change-3 {
    0% { border-color: #08d9d6; }  /* Teal */
    25% { border-color: #ff9a8c; } /* Soft coral */
    50% { border-color: #b83b5e; } /* Muted raspberry */
    75% { border-color: #6a2c70; } /* Deep purple */
    100% { border-color: #08d9d6; }/* Teal */
}
