/* body, html {
    height: 100%;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    background: #f0f0f0;
  } */

.story-book-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 0 auto; */
    /* margin-top: 15vh; */
    /* width: 100%; */
    /* max-width: 800px; */
    /* margin-bottom: 3%; */

    transition: all 1.5s ease-in-out;

}

.story-book-page::before {
  content: "";
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.63), rgba(0, 0, 0, 0.623)); */
  position: fixed;
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* filter: blur(5px); */
  backdrop-filter: blur(5px);
  margin: 25px; /* this will do the trick */
}
  
  .book-container {
    display: flex;
    /* position: absolute; */
    /* top: 0px; */
    width: 100%;
    height: 100%;
    /* border: 1px solid grey; */
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* backdrop-filter: blur(10px); */
  }

  .page-nav {
    /* height: 100%; */
    display: flex;
    margin-top: 2vh;
    height: 90vh;
    width: 100%;
    z-index: 200;
  }

  .page-nav-dir {
    /* height: 100%; */
    height: 100%;
    width: 50%;
  }
  
  
  /**=============== Book Nav ===============**/
  /* #device-view {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 350px;
    height: 500px;
    
    display: flex;
    flex-direction: rows;
    background-color: red;
  } */
  
  

  
  /**=============== Book Starts Here ===============**/
  #book {
      display: flex;
      position: absolute;
      flex-direction: row;
      width: 67.5vh;
      height: 90vh;
      padding: 10px;
      transition: all 1.5s ease-in-out;
  
      -webkit-perspective: 12400px;
              perspective: 12400px;
  
      transform-style: preserve-3d;
      transform-origin: left center;
  }
  
  .page {
      position: absolute;
      width: 67.5vh;
      height: 90vh;
      margin-left: -10px;
      text-align: center;
      /* line-height: 400px; */
      background: #fff;

      font-weight: 700;
      color: white;
  
      transform-style: preserve-3d;
      transform-origin: left center;
  
      transition: all 1.5s ease-in-out;
  }

  .page.animated {
    background-position: left center;
    animation: panLeft 20s ease-in-out infinite;
  }

  /* .page.animated .page-text {
    display: flex;
    position: absolute;
    bottom: 0;
    background: red;
  } */

  .page-text {
    display: flex;
    position: absolute;
    top: 46em;
    /* bottom: 3em; */
    /* background: red; */
    border-radius: 10px 20px;
    max-width: 85%;
    max-height: 9em;
    padding: 10px;
    padding-top: 5px;
    text-align: justify;
    text-justify: inter-word;
    /* width: 7%; */
    /* height: 100%;     */

    /* font-size: 20px; */
    /* position: absolute; */
    /* bottom: 5%; */
    /* width: 75%; */
    /* max-width: 75%; */
    background: var(--background-primary-color);
    /* border-radius: 10px; */
    /* padding: 10px; */
    backdrop-filter: blur(10px);
    color: #eee;
    text-shadow: 0px 0px 5px #000;
    transition: all 1s ease;
    box-shadow: -3px -3px 6px var(--shadow-dark-light), 3px 3px 6px var(--shadow-dark-dark);
    overflow-y: scroll;
}

.page-num {
    position: fixed;
    bottom: 0;
    right: 7.5vw;
    color: #eee;
    z-index: 101;
}

  @keyframes panLeft {
    0% {
      background-position: left center;
    }
    50% {
        background-position: right center;
    }
    100% {
      background-position: left center;
    }
  }
  
  .page::before {
      content: '';
      position: absolute;
      width: 7%;
      height: 100%;    
  }
  
  .page.back::before {
      right: 0px;
      background: linear-gradient(to left, rgba(0,0,0,0.8), transparent);
      /* border-right: 2px solid lightgrey;  */
  }
  
  .page.front::before {
      left: 0px;
      background: linear-gradient(to right, rgba(0,0,0,0.8), transparent);
      /* border-left: 2px solid lightgrey;  */
  }
  
  .page.front {
      box-shadow: 5px 5px 10px rgba(0,0,0,0.1);
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      -webkit-backface-visibility: hidden; 
                backface-visibility: hidden; 
  }
  
  /* .page.front.open {
      transform: rotateY(-180deg);
  } */
  
  .page.back {
      transform-origin: right center;
      transform: rotateY(180deg);
      translate: -100%;
      box-shadow: -5px 5px 10px rgba(0,0,0,0.1);
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;    
      -webkit-backface-visibility: hidden; 
                backface-visibility: hidden; 
  }
  
  /* .page.back.open {
      transform: rotateY(0deg);
  } */
  
  .cover {
      margin: -10px;
      padding: 10px;
      /* width: 310px;
      height: 420px; */
      width: calc(68.5vh);
      height: calc(92vh);
      padding-right: 0px;
  /*     z-index: 100; */
      box-shadow: 5px 5px 20px rgba(0,0,0,0.4),
                  inset 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
      
      background: #e1e1e1;
      
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
  }
  
  /* #cover-back.open {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
  } */
  
  .cover.open {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      background: #f1f1f1;
  }
  
  #Page1 {
        -webkit-backface-visibility: hidden; 
                backface-visibility: hidden; 
  }


  
/* .page-image {
    z-index: -250;
} */

@media screen and (max-width: 480px) {
    #book {
        width: 90vw;
        height: 120vw;
        padding-bottom: 2vh;
    }

    .page-nav {
        height: 120vw;
    }
    
    .page {
        width: 90vw;
        height: 120vw;
    }

    .cover {
        width: 92vw;
        height: 124vw;
    }
}

@media screen and (min-width: 480px) {
    .page-text {
        font-size: 24px;
        bottom: 5%;
    }
}
  