.pricing-container {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    align-items: center;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE/Edge */
}

.pricing-container::-webkit-scrollbar {
    display: none; /* Chrome/Safari/Opera */
}

@media (min-width: 768px) {
    .pricing-container {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-items: stretch;
    }

    .card {
        flex: 0 0 250px; /* Fixed width in landscape */
        height: 400px; /* Fixed height in landscape */
    }

    .card:hover {
        flex: 0 0 300px; /* Larger width on hover */
        height: 450px; /* Larger height on hover */
        z-index: 1; /* Bring hovered card to front */
    }
}

.card {
    width: 300px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
}

.card:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.card-content {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.card-content h2 {
    margin: 10px 0;
}

.price {
    font-size: 24px;
    margin: 10px 0;
}

.card-content ul {
    list-style: none;
    padding: 0;
    margin: auto 0;
}

.card-content ul li {
    margin-bottom: 10px;
}

button {
    cursor: pointer;
    padding: 10px 20px;
    border: none;
    background-color: dodgerblue;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 20px;
}

.confirm-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.cancel-confirm-dialog {
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    text-align: center;
}