.bookshelf {
    visibility: hidden;
    top: 0px;
    width: 100%;
    height: 100vh;
    text-align: center;
    font-family: sans-serif;
    -webkit-perspective: 1200px;
    overflow: hidden;
}

.bookshelf section {
    position: absolute;
    left: 50%;
    
    width: 18.5em;
    height: 25em;
    
    border-radius: 10px;
    
    margin-left: -9.25em;
    margin-top: 15em;
    
    background-color: white;
    background-size: 100%;
    
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
        transform-style: preserve-3d;
    
    -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in;
        -ms-transition: all 200ms ease-in;
            -o-transition: all 200ms ease-in;
            transition: all 200ms ease-in;
}

#bookshelf.bookshelf.landing-shelf section {
    margin-top: 18em;
}



/*
* Controls
*/

.paginate-controls {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 41.5em;
    width: 100%;
    z-index: 100;
}

.paginate-controls.landing-shelf {
    width: 100%;
    top: 45em;
}



.pagination {
    color: #eee;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 5px;
    max-width: 350px;
}

.paginate-el {
    margin: 0 8px;
    cursor: pointer;
    color: #eeeeee77;
    transition: all 0.2s ease-in-out;
}

.paginate-el.selected {
    margin: 0 15px;
    cursor: pointer;
    color: #eee;
    font-size: 20px;
}


#controls a  {
    color: white; 
    cursor: pointer;
}

#controls a:hover  {
    color: 66FFFF;
}



@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&family=Roboto:wght@300;400;500;900&display=swap");

* {
    box-sizing: border-box;
}

.book-cover {
    width: 100%;
    height: 100%;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
    filter: blur(1px);
    border: 1px solid #5b5b5b;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: self-start;

    backdrop-filter: blur(5px);
    -webkit-box-reflect: below -1px
    linear-gradient(to top, white 5%, transparent 45%);
}

.book-cover.summary-cover {
    width: 325px;
    height: 450px;
}

.msg-container {
    visibility: hidden;
    position: relative;
    height: 100%;
    width: 100%;
}

.msg-txt {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.25rem;
}

.msg-refresh {
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
}

.msg-refresh svg {
    height: 25px;
    width: 25px;
    color: #eee;
}

.book-cover-active {
    filter: blur(0px);
    transition: all 0.2 ease-in-out;
}

.blur-filter {
    position: absolute;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(2px);
}

.book-summary {
    visibility: hidden;
    margin-top: 30px;
    margin-left: 5%;
    max-height: 40%;
    width: 90%;
    padding: 10px;
    padding-top: 0.5em;
    color: white;

    text-align: justify;
    
    border-radius: 10px 20px;

    background: #7c7c7c77;
    backdrop-filter: blur(10px);
    color: #eee;
    text-shadow: 0px 0px 5px #000;
    transition: all 1s ease;
    box-shadow: -3px -3px 6px var(--shadow-dark-light), 3px 3px 6px var(--shadow-dark-dark);
    overflow-y: scroll;
}

.book-summary.landing-shelf{
    background: transparent;
}

.book-summary.book-summary-active {
    visibility: visible;
}

.book-genre {
    text-transform: uppercase;
    color: #fff;
    background: #1b740277;
    padding: 7px 18px 7px 25px;
    display: inline-block;
    border-radius: 0 20px 20px 0px;
    letter-spacing: 2px;
    font-size: 0.8rem;
    backdrop-filter: blur(3px);
}

.book-title {
    padding: 5px;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.7));
}

.book-title svg {
    margin-top: -15px;
    margin-bottom: -15px;
    width: 100%;
}


.book-title svg text {
    transition: all 0.5s ease-in-out;

    font-family: "Texturina", serif;
    font-weight: 700;

    /* font-family: "Open Sans", sans-serif; */
    /* font-weight: 700; */

    /* font-family: "Quicksand", sans-serif; */
    /* font-weight: 700; */

    /* font-family: "League Spartan", sans-serif; */
    /* font-weight: 700; */

    /* font-family: "Pacifico", cursive; */
    /* font-weight: 700; */
    
    /* font-family: "Neucha", cursive; */
    /* font-weight: 700; */
    
    /* font-family: "Annie Use Your Telescope", cursive; */
    /* font-weight: 700; */

    /* font-family: "Gentium Book Plus", serif; */
    /* font-weight: 700; */

    /* font-family: "Fredoka", sans-serif; */
    /* font-weight: 700; */

    /* font-family: "Libre Baskerville", serif; */
    /* font-weight: 700; */

    /* font-family: "Alegreya Sans", sans-serif; */
    /* font-weight: 700; */

    /* font-family: "Oleo Script", system-ui; */
    /* font-weight: 700; */
}

.FairyTale {
    font-family: "Texturina", serif !important;
}
.Adventure {
    font-family: "Open Sans", sans-serif !important;
}
.Mystery {
    font-family: "Quicksand", sans-serif !important;
}
.AnimalsNature {
    font-family: "League Spartan", sans-serif !important;
}
.Fantasy {
    font-family: "Pacifico", cursive !important;
}
.Bedtime {
    font-family: "Neucha", cursive !important;
}
.NurseryRhymesPoem {
    font-family: "Annie Use Your Telescope", cursive !important;
  }
.Friendship {
    font-family: "Gentium Book Plus", serif !important;
  }
.FamilyAndLove {
    font-family: "Fredoka", sans-serif !important;
  }
.CourageAndBravery {
    font-family: "Libre Baskerville", serif !important;
  }
.KindnessAndCompassion {
    font-family: "Alegreya Sans", sans-serif !important;
  }
.Discovery {
    font-family: "Oleo Script", system-ui !important;
}

.book-cover p {
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 5px 0px 5px;
}

.book-icon {
    color: #fff;
    width: 22px;
    height: 22px;
    margin-right: 7px;
}

.delete-button {
    position: absolute;
    bottom: 5px;
    left: 5px; 
    width: 22px;
    height: 22px;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;
}

.delete-button:hover {
    opacity: 1;
}