.book-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    /* left: 0; */
    /* overflow: scroll; */
}

/* .book-page-image {
    width: 100vh;
    height: 100vh;
    display: flex;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0px;
    overflow-x: scroll;
} */

/* .book-page-image {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}

.book-page-image img{
    transform: scale(2);
    transition: transform 0.25s ease;
    cursor: grabbing;
} */

/* .book-page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
} */

.get-link {
    position: absolute;
    top: 1vw;
    right: 1vw;
}

.book-page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* position: absolute; */
    touch-action: none; /* Prevents default touch behaviors */
    will-change: transform; /* Optimizes performance */
    transition: transform 0.05s ease-out; /* Smooth movement */
}

.book-page-text {
    font-size: 20px;
    width: 75%;
    /* height: 50%; */
    transition: all 1s ease;
    
    background: var(--background-primary-color);
    border-radius: 10px 20px;
    padding: 10px;
    backdrop-filter: blur(10px);
    color: #eee;
    text-shadow: 0px 0px 5px #000;
    transition: all 1s ease;
    box-shadow: -3px -3px 6px var(--shadow-dark-light), 3px 3px 6px var(--shadow-dark-dark);
}

.book-page-text.filled {
    font-size: 20px;
    position: absolute;
    bottom: 5%;
    width: 75%;
    max-width: 75%;
    background: var(--background-primary-color);
    border-radius: 10px 20px;
    padding: 10px;
    backdrop-filter: blur(10px);
    color: #eee;
    text-shadow: 0px 0px 5px #000;
    transition: all 1s ease;
    box-shadow: -3px -3px 6px var(--shadow-dark-light), 3px 3px 6px var(--shadow-dark-dark);
}

/* .page-image {
    margin: 4vw;
} */

/* .page-image img{
    width: 100%;
    margin: 0px;
} */

.book-page .page-nav {
    color: #eee;
    display: flex;
    justify-content: space-between;
    position: absolute;
    align-items: center;
    bottom: 0;
    height: 5%;
}

/* .book-page-image {
    width: 100vh;
    height: 100vh;
    object-fit: cover;
    position: relative;
    left: 50%;
    border-radius: 0px;
} */





.nav-arrow {
    cursor: pointer;
    width: 35px;
    margin-bottom: -5px;
}

/* Add hover effect */
.book-page-content:hover {
    cursor: grab;
}

.book-page-content:active {
    cursor: grabbing;
}

.loading-message {
    text-align: center;
    margin-top: 15px;
    color: #666;
    font-size: 16px;
}

.page-nav {
    width: 100%;
    padding: 0 20px;
    max-width: 400px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px 20px 0 0;
    padding: 10px 20px;
}

.page-num {
    color: white;
    font-size: 16px;
    font-weight: 500;
}

.num-container {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.3);
    padding: 5px 15px;
    border-radius: 15px;
}

.nav-arrow {
    transition: opacity 0.2s ease;
}

.nav-arrow:hover {
    opacity: 0.8;
}