/* * {
    border: 1px solid #333;
} */

/* .logo-img.small img{ */

.book-cover.book-cover-active.no-reflection {
    -webkit-box-reflect: unset;
    /* background-color: red; */
}

.nav-container.small {
    width: 20%;
    transition: all 1.5s ease;
}

.story-summary-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.story-summary-container .button-link {
    z-index: 1;
    margin-bottom: 3em;
}

.story-summary-container button {
    backdrop-filter: blur(25px);
}

.story-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    background: var(--background-primary-color);
    color: #e1e1e1;
    backdrop-filter: blur(50px);
    box-shadow: -6px -6px 12px var(--shadow-dark-light), 6px 6px 12px var(--shadow-dark-dark);
    max-width: 92%;
    position: relative;
    margin-top: 175px;
    text-shadow: 0px 0px 5px #1e1e1e;
}

.story-idea {
    padding: 5px 10px;
    margin: 5px 10px;
    display: block;
    font-size: 1.25em;
}

.story-title-empty {
    position: relative;
    border-radius: 10px;
    box-shadow: -2px -2px 4px  var(--shadow-dark-light), 2px 2px 4px var(--shadow-dark-dark);
    backdrop-filter: blur(6px);
    padding: 5px 10px;
    margin-bottom: 10px;
    display: flex;
    font-weight: bold;
    font-size: 24px;
    max-width: 80%;
}

@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&family=Annie+Use+Your+Telescope&family=Fredoka:wght@300..700&family=Gentium+Book+Plus:ital,wght@0,400;0,700;1,400;1,700&family=League+Spartan:wght@100..900&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Neucha&family=Oleo+Script:wght@400;700&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Pacifico&family=Quicksand:wght@300..700&family=Texturina:ital,opsz,wght@0,12..72,700;1,12..72,700&display=swap');

/* .story-title svg text { */
    /* font-family: "Roboto", sans-serif !important; */
    /* font-family: "Anton", sans-serif, monospace; */
    /* font-weight: 900; */
/* } */

.story-summary {
    position: relative;
    display: flex;
    padding: 5px 10px;
    /* margin: 5px 10px; */
    font-size: 20px;
    max-width:100%;
}

.story-title-image {
    position: relative;
    display: flex;
    /* margin-bottom: 10px; */
    max-width: 100%;
    margin-top: 10px;
}

.summary-image {
    width: fit-content;
    max-width: 100%;
}

.transparent-corner-button {
    position: absolute;
    visibility: hidden;
    top: 0px;
    right: 5px;
    width: 16px !important;
    height: 16px !important;
    opacity: 0.5;
    cursor: pointer;
}

.story-title:hover #refresh-title-btn {
    visibility: visible;
  }

.story-summary:hover #refresh-summary-btn {
    visibility: visible;
  }

.story-title-image:hover #refresh-title-image-btn {
    visibility: visible;
    top: 30px;
    right: 30px;
  }

.animate-images-container {
    display: none; /* normally: flex; turned off because SVD doesnt work great. */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px;
    text-align: center;
}

.animate-images-checkbox {
    width: 20px;
    margin: 10px;
}

.loading-animation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
}

.spinner {
    font-size: 2rem;
    color: #4a4a4a;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-animation p {
    margin-top: 1rem;
    font-size: 1rem;
    color: #4a4a4a;
}