@import url('https://fonts.googleapis.com/css2?family=Poor+Story&family=Source+Code+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&family=Annie+Use+Your+Telescope&family=Fredoka:wght@300..700&family=Gentium+Book+Plus:ital,wght@0,400;0,700;1,400;1,700&family=League+Spartan:wght@100..900&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Neucha&family=Oleo+Script:wght@400;700&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Pacifico&family=Quicksand:wght@300..700&family=Texturina:ital,opsz,wght@0,12..72,700;1,12..72,700&display=swap');

/* COLORS */
:root {
  --background-primary-color: #8083882e;
  --background-darker-color: #8083889d;
  --background-message-ai: #33353b84;
  --background-message-user: #042153a5;
  --background-input: #26488b3b;
  --button: #002c9d8d;
  --shadow-dark-light: #1a1a1b2e;
  --shadow-dark-dark: #0000002e;
  --danger-color: #dc3545;
  --warning-color: #ffc107;
  --info-color: #17a2b8;
  --light-color: #f8f9fa;
  --dark-color: #343a40;
  --text-primary-color: #e1e1e1;
}
/* COLORS */

/* VARIABLES */
/* :root {
  --font-family: 'Poor Story', system-ui;
  --text-align: center;
  --background-color: #e0e5ec;
  --margin: 0;
  --padding: 0;
  --box-sizing: border-box;
  --min-height: 100vh;
  --scrollbar-width: 10px;
  --scrollbar-color: var(--primary-color) var(--light-color);
} */




* {
  text-align: center;
  /* font-family: 'Poor Story', cursive; */
  font-family: 'Poor Story', system-ui;
}

body {
    background-color: #e0e5ec;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    min-height: 100vh;
    min-height: fill-available;
    min-height: -webkit-fill-available;
    /* font-family: 'Poor Story', system-ui; */
}

html {
    height: fill-available;
    height: -webkit-fill-available;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
}

.App-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
}

.App-container::before {
    content: "";
    position: fixed;
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(5px);
    /* margin: 10px; */
}

.start-demo-instructions {
    font-size: 20px;
    font-weight: bold;
    margin: 10px;
    width: 50%
}


.credit-counter {
    display: flex;
    position: absolute; /* Position it absolutely */
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    color: #e1e1e1; /* Match the header button color */
    font-size: 16px; /* Adjust font size as needed */
    z-index: 2001; /* Ensure it appears above other elements */
}

.credit-svg {
    width: 24px;
}

.credit-value {
    margin-top: -2px;
}

.nav-container {
    left: 50%;
    transform: translate(-50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 2em;
    /* top: 3vw; */
    /* left: 50%; */
    /* transform: translate(-50%); */
    color: #333;
    /* margin-top: 3vh; */
    width: 6.5em;
    height: 6.5em;
    z-index: 1000;
    transition: all 1.5s ease;
}

.nav-container img {
    width: 100%;
    height: 100%;
    filter: drop-shadow(2px 2px 2px #716f6f);
    /* transition: all 1.5s ease; */
  }

.nav-container.small{
    transform: translate(0%);
    flex-direction: row;
    width: 3em;
    height: 3em;
    /* top: 0; */
    left: 2em;
    /* transition: all 1.5s ease; */
}

.nav-container.tiny{
    transform: translate(0%);
    flex-direction: row;
    width: 2em;
    height: 2em;
    /* top: 0; */
    left: 1.5em;
    /* transition: all 1.5s ease; */
}

.nav-container.transparent {
    opacity: 35%;
    /* transition: all 1.5s ease; */
}

.nav-container.small img{
    width: 3em;
    height: 100%;
    filter: drop-shadow(2px 2px 2px #716f6f);
    transition: all 1.5s ease;
}

.profiles-container {
    display: flex;
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    visibility: visible;
}

#demo-profiles-container {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.start-demo-instructions {
    font-size: 20px;
    font-weight: bold;
    margin: 10px;
    margin-right: 0px;
    width: 300px;
    color: #e1e1e1 !important;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.8);
}

.profile-btn {
    width: fit-content;
    border: none;
    outline: none;
    margin: 10px;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    color: #ffffff82;
}

#demo-profiles-container .profile-btn {
    margin: 0px;
    color: #ffffffff !important;
}

.profile-btn.active {
    opacity: 1;
    filter: drop-shadow(2px 2px 4px #a8adbbce);
    color: #ffffffff !important;
}

.profile-img {
    max-width: 75px;
    box-shadow: none;
    margin: 0px;
    margin-bottom: -10px;
    filter: drop-shadow(2px 2px 4px #a4a7b4);
    cursor: pointer;
}

.profile-img:hover {
    filter: drop-shadow(-2px -2px 4px #a4a7b4);
}

.profile-name {
    font-family: 'Poor Story', cursive;
    font-size: 20px;
    padding: 2px;
    font-weight: bold;
    width: fit-content;
}

/* img {
    border-radius: 20px;
    width: 100%;
    filter: drop-shadow(3px 3px 3px #3d3d3d);
} */


.landing-shelf-container {
    transition: all 1.5s ease-in-out;
}

.install-app-container {
    display: flex;
    position: absolute;
    bottom: 25px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 100;
    margin: 20px;
    border-radius: 15px;
    background-color: var(--background-primary-color) !important;
    backdrop-filter: blur(6px);
    box-shadow: -6px -6px 10px var(--shadow-dark-light), 6px 6px 10px var(--shadow-dark-dark);
}

.install-app-instructions {
    font-size: 20px;
    font-weight: bold;
    margin: 10px;
    width: 50%
}

.install-app-btn {
    border: none;
    outline: none;
    padding: 6px 12px;
    margin: 6px;
    border-radius: 10px;
    background: #8083882e;
    color: #030303;
    font-size: 16px;
    cursor: pointer;
    box-shadow: -6px -6px 10px var(--shadow-dark-light), 6px 6px 10px var(--shadow-dark-dark);
}

.install-dismiss-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    opacity: 0.6;
}


.install-app-btn:active {
    box-shadow: -2px -2px 4px var(--shadow-dark-light), 2px 2px 4px var(--shadow-dark-dark);
}

.install-app-btn:hover {
    box-shadow: -2px -2px 4px var(--shadow-dark-light), 2px 2px 4px var(--shadow-dark-dark);
}

/* Responsive styles for tablets */
/* @media (max-width: 768px) {
  img {
    max-width: 60%;
  }
} */

/* Responsive styles for mobile phones */
@media (max-width: 480px) {
  /* .App {
    padding: 20px 0;
  } */

  /* img {
    max-width: 80%;
  } */
}

/* Updated floating confirmation styles */
.floating-confirmation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(20px);
    border-radius: 15px;
    padding: 30px;
    width: 80%;
    max-width: 400px;
    z-index: 1000;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    text-shadow: 0px 0px 5px #1e1e1e;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.floating-confirmation h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.floating-confirmation p {
    margin-bottom: 15px;
    font-size: 1.1em;
    line-height: 1.4;
}

.floating-confirmation strong {
    font-size: 1.2em;
    color: #dadada;
    font-weight: bold;
}

.floating-confirmation-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
}

.floating-confirmation button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    transition: all 0.3s ease;
}

.floating-confirmation button.confirm {
    background-color: rgba(220, 53, 69, 0.8);
    color: white;
}

.floating-confirmation button.cancel {
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
}

.floating-confirmation button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.floating-confirmation button.confirm:hover {
    background-color: rgba(220, 53, 69, 1);
}

.floating-confirmation button.cancel:hover {
    background-color: rgba(255, 255, 255, 0.3);
}