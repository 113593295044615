/* src/SkeletonLoader.css */

.skeleton-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 20px; */
    color: #eee;
    text-shadow: 0px 0px 5px #000;
    /* background: linear-gradient(90deg, #e0e5ec, #cfd4d9, #e0e5ec); */
    background: linear-gradient(90deg, rgba(84, 79, 79, 0.5), rgba(143, 143, 143, 0.8), rgba(77, 77, 77, 0.5));
    background-size: 200% 100%;
    border-radius: 8px;
    animation: loading 3.0s infinite;
  }

  .loader-content {
    padding: 20px;
  }
  
  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }