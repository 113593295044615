.ai-profile-creator {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
}

.chat-container {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.message {
    max-width: 80%;
    margin-bottom: 15px;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 1.4;
}

.message.ai {
    align-self: flex-start;
    color: #e0e0e0;

    background-color: var(--background-message-ai);
    backdrop-filter: blur(12px);
    box-shadow: -2px -2px 4px var(--shadow-dark-light), 2px 2px 4px var(--shadow-dark-dark);
}

.message.user {
    align-self: flex-end;
    color: #e0e0e0;

    background-color: var(--background-message-user);
    backdrop-filter: blur(12px);
    box-shadow: -2px -2px 4px var(--shadow-dark-light), 2px 2px 4px var(--shadow-dark-dark);
}

.chat-input-form {
    display: flex;
    padding: 10px;
    margin-bottom: 10px;
}

.chat-input {
    color: #e0e0e0;
    flex-grow: 1;
    padding: 10px;
    border-radius: 20px;
    font-size: 16px;
    margin-right: 10px;
    border: none;

    background-color: var(--background-input);
    backdrop-filter: blur(12px);
    box-shadow: -2px -2px 4px var(--shadow-dark-light), 2px 2px 4px var(--shadow-dark-dark);
}

.chat-input::placeholder {
    color: #cdcaca;
}

.chat-input:focus {
    outline: none;
    border: none;
}

.chat-send-button {
    margin: auto;
    height: fit-content;
    width: fit-content;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    background-color: var(--button);
    backdrop-filter: blur(12px);
    box-shadow: -2px -2px 4px var(--shadow-dark-light), 2px 2px 4px var(--shadow-dark-dark);
}

.chat-send-button:hover {
    background-color: #0066cc;
}

.view-profile-info {
  display: block;
  margin: 10px auto;
  padding: 10px 15px;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  background-color: var(--button);
  backdrop-filter: blur(12px);
  box-shadow: -2px -2px 4px var(--shadow-dark-light), 2px 2px 4px var(--shadow-dark-dark);
}

.view-profile-info:hover {
  background-color: #0066cc;
}

@media (max-width: 600px) {
    .ai-profile-creator {
        height: 100vh;
        border-radius: 0;
    }
}