.App-footer {
    text-align: center;
    position: fixed;
    padding: 5px;
    padding-bottom: 20px;
    bottom: -55px;
    /* bottom: -120px; */
    width: 90%;
    border-radius: 15px;
    background-color: var(--background-primary-color);
    /* background-color: var(--background-darker-color); */
    backdrop-filter: blur(3px);
    box-shadow: -2px -2px 4px var(--shadow-dark-light), 2px 2px 4px var(--shadow-dark-dark);
    font-size: 14px;
    transition: all 0.3s ease;
    z-index: 100;
}

.App-footer.logged-in {
    bottom: -120px;
}

.App-footer:hover {
    bottom: -15px;
    background-color: var(--background-darker-color);
    transition: all 0.3s ease;
}

.App-footer.logged-in:hover {
    bottom: -30px;
    transition: all 0.3s ease;
}