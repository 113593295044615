.book-page-image {
    /* overflow: hidden; */
    width: 100vw; /* Adjust based on your needs */
    height: 100vh; /* Adjust based on your needs */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;

    overscroll-behavior: none; /* Disable pull-to-refresh and overscroll glow effect */

}

/* .book-page-image img { */
.book-page-image img{
    transform: scale(1.2);
    transition: transform 0.25s ease;
    cursor: grabbing;
    min-zoom: 50%;
}