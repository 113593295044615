/* .app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
} */

.start-story {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 0;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    transition: all 3s ease;
}
.start-story.show {
    max-height: 500px;
    visibility: visible;
    opacity: 1;
}

.start-story.show h1 {
    color: #eee;
    text-shadow: 0px 0px 3px #313131;
}

.refresh-button {
    display: flex;
    background: transparent;
    width: fit-content;
}
.input-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    bottom: 20px;

    /* left: 50%;
    transform: translateX(-50%); */
    /* max-width: 80%; */
    /* width: 60%; */
    /* left: calc(20% - 10px); */
    /* align-items: center; */
}

    .neu-button {
        border: none;
        outline: none;
        padding: 10px 20px;
        margin-bottom: 20px !important;
        border-radius: 10px;
        background: #808388aa;
        backdrop-filter: blur(25px);
        color: #eee;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        box-shadow:  6px 6px 12px #2a2c2eaa,
                        -6px -6px 12px #5a5c62aa;
        /* box-shadow: inset -2px -2px 4px #2a2c2eaa, inset 2px 2px 4px #5a5c62aa,
                        2px 2px 4px #2a2c2eaa, -2px -2px 4px #5a5c62aa; */
        min-width: 200px;
        max-width: 80%;
        transition: all 0.3s ease;
    }

    .neu-button.fit {
        min-width: 50px;
        width: fit-content;
        margin-bottom: 10px !important;
    }

    .neu-button.submit {
        background: #1447a1aa;
    }

    .neu-button:hover {
        /* box-shadow: inset -2px -2px 4px #ffffff, inset 2px 2px 4px #babecc,
                    2px 2px 4px #ffffff, -2px -2px 4px #babecc; */
        /* box-shadow: inset -2px -2px 4px #2a2c2eaa, inset 2px 2px 4px #5a5c62aa,
                    2px 2px 4px #2a2c2eaa, -2px -2px 4px #5a5c62aa; */
        background: #5286bacc;
        box-shadow: 2px 2px 4px #2a2c2eaa, -2px -2px 4px #5a5c62aa;
                    
        transition: all 0.3s ease;
    }

    .neu-button.skeleton-loader {
        display: block;
        padding: 0px !important;
    }
    
    .neu-button:active {
        /* box-shadow: 2px 2px 4px #ffffff, -2px -2px 4px #babecc; */
        box-shadow: 2px 2px 4px #2a2c2eaa, -2px -2px 4px #5a5c62aa;
    }
    
    .neu-input {
        /* background: #e0e5ec; */
        background: #808388aa;
        border: none;
        padding: 10px;
        border-radius: 10px;
        margin: 10px 0;
        backdrop-filter: blur(25px);
        /* box-shadow: inset -2px -2px 5px #ffffff, inset 2px 2px 5px #babecc; */
        box-shadow:  3px 3px 6px #2a2c2eaa,
                        -3px -3px 6px #5a5c62aa;
        color: #eee; 
    }

    .input-field {
        background: none;
        border: none;
        resize: none;
        font-size: 20px;
        width: fill-available;
        height: fit-content;
        text-align: center;
        overflow-y: scroll;
        line-height: 1.0; 
        box-sizing: border-box;
        word-wrap: break-word;
        color: #eee;
    }

    .input-field:focus {
        outline: none;
        border: none;
    }

    .input-field::placeholder {
        outline: none;
        border: none;
        color: #eee;
    }
    
    .neu-send-button {
        border: none;
        outline: none;
        padding: 5px 10px;
        border-radius: 10px;
        background: #80838875;
        color: #eee;
        /* color: #333; */
        cursor: pointer;
        /* box-shadow: -4px -4px 8px #ffffff, 4px 4px 8px #babecc; */
        box-shadow:  6px 6px 12px #2a2c2eaa,
                    -6px -6px 12px #5a5c62aa;
        /* margin-bottom: 10px; */
        font-size: 20px;
        font-weight: bold;
        white-space: nowrap;
        transition: all 0.3s ease;
    }

    .neu-send-button:hover {
        /* box-shadow: inset -2px -2px 4px #2a2c2eaa, inset 2px 2px 4px #5a5c62aa,
                    2px 2px 4px #2a2c2eaa, -2px -2px 4px #5a5c62aa; */
        box-shadow: 2px 2px 4px #2a2c2eaa, -2px -2px 4px #5a5c62aa;
        transition: all 0.3s ease;
    }

    .neu-send-button:active {
        /* box-shadow: 2px 2px 4px #ffffff, -2px -2px 4px #babecc; */
        box-shadow: 2px 2px 4px #2a2c2eaa, -2px -2px 4px #5a5c62aa;
    }

    .neu-select {
        margin: 5px;
        background: rgba(128, 131, 136, 0.5);
        border: none;
        padding: 5px;
        border-radius: 10px;
        color: #eee;
        font-size: 16px;
        cursor: pointer;
        backdrop-filter: blur(10px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
        width: 45%;
    }

    .neu-select:hover {
        background: rgba(128, 131, 136, 0.7);
    }

    .neu-select:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(82, 134, 186, 0.5);
    }

    .neu-select option {
        background: rgba(128, 131, 136, 0.9);
        color: #eee;
        padding: 10px;
    }

    /* .neu-select,
    .neu-select option {
        background: rgba(128, 131, 136, 0.9);
        color: #eee;
    } */

.neu-text {
    color: white;
    text-shadow: 0px 0px 5px #1e1e1e;
}

/* Responsive styles for tablets */
@media (max-width: 768px) {
    .input-group {
        flex-direction: column;
        width: 80%;

        /* left: calc(10% - 10px); */
    }
  
    .neu-send-button {
        margin-left: 0;
        margin-top: 10px;
        width: 100%;
    }
  }
  
  /* Responsive styles for mobile phones */
  @media (max-width: 480px) {
    /* .input-group { */
        /* width: 90%; */
        /* margin: 0 auto; */
        /* left: calc(10% - 10px); */
    /* } */

    .button-group {
        flex-direction: column;
    }
  
    .neu-button {
        margin: 5px;
    }
  }
