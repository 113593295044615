.ai-profile-creator {
  z-index: 1;
  overflow: scroll;
  width: 95%;
  height: 100%;
}

.chat-container {
  /* position: absolute; */
  z-index: 1;
  margin-top: 14em;
  overflow: hidden;
}


.profile-info-container {
    z-index: 1;
    overflow: scroll;
    width: 95%;
    height: 100%;
}

.profile-information {
    /* position: absolute; */
    z-index: 1;
    margin-top: 14em;
    overflow: hidden;
}

.profile-information h1 {
    color: #eee;
    text-shadow: 0px 0px 5px #1e1e1e;
}

.profile-data {
    color: #eee;
    text-shadow: 0px 0px 5px #1e1e1e;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 600px;
    background: #8d8d8e77;
    border-radius: 20px;
    box-shadow: 5px 5px 10px #1a1a1a45,
                -5px -5px 10px #3f3e3e45;
    margin: 15px;
    backdrop-filter: blur(10px);
  }

  .profile-header-label {
    /* display: flex; */
    font-size: 1.5em; /* You can adjust the size if needed */
    /* color: #333; */
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .profile-category {
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: #8d8d8e22;
    border-radius: 15px;
    box-shadow: 5px 5px 10px #1a1a1a45,
                -5px -5px 10px #3f3e3e45;
    margin: 15px;
    /* padding-bottom: 15px; */
  }

  .profile-entry {
    font-size: 1.2em;;
    margin: 15px;
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .profile-subsection {
    margin: 15px;
    border: 4px solid grey;
  }

  .profile-subsection-array {
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: #8d8d8e22;
    border-radius: 15px;
    box-shadow: 5px 5px 10px #1a1a1a45,
                -5px -5px 10px #3f3e3e45;
    margin: 15px;
    margin-top: 0px;
    padding-top: 15px;
  }

  .profile-entry-array {
    /* display: flex;
    flex-direction: row; */
    margin: 15px;
    margin-top: 0px;
    margin-bottom: 15px;
  }

  /* .profile-subsection-array .neu-form-input {
    width: 75%;
  } */

  .neu-subsection-label {
    font-size: 1.2rem; /* You can adjust the size if needed */
    /* color: #333; */
    margin-bottom: 15px;
    /* margin-right: 10px; */
    font-weight: bold;
  }


  .neu-form-group-test {
    margin: 15px;
    border: 3px solid green;
  }
  
  .neu-form-label {
    font-size: 1.2rem; /* You can adjust the size if needed */
    /* color: #333; */
    /* margin-bottom: 10px;
    margin-right: 10px; */
    font-weight: bold;
  }

  /* .neu-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 600px;
    padding: 30px;
    background: #e0e5ec;
    border-radius: 20px;
    box-shadow: 20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff;
    margin-top: 40px;
  } */
  

  
 


  
  .neu-form-input,
  .neu-form-select {
    color: #eee;
    background: #1d1d1d22;
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin-left: 15px;
    /* width: ; */
    font-size: 1.2rem; /* Keeping font size consistent */
    box-shadow: inset 4px 4px 6px #1a1a1a45,
                inset -4px -4px 6px #63616145;
    outline: none; /* Remove default focus outline */
  }
  
  .neu-form-input:focus,
  .neu-form-select:focus {
    box-shadow: inset 8px 8px 8px #1a1a1a45,
                inset -8px -8px 8px #3f3e3e45;
  }
  
  .neu-form-input::placeholder,
  .neu-form-select::placeholder {
    color: #d3cbcb;
  }
  
  .neu-form-button {
    background: #81828477;
    border: none;
    border-radius: 10px;
    padding: 15px 30px;
    margin: 15px;
    font-size: 1rem; /* Consistent font size */
    font-weight: bold;
    color: #eee;
    box-shadow: 3px 3px 6px #1a1a1a45,
               -3px -3px 6px #3f3e3e45;
               /* inset -3px -3px 6px #1a1a1a45,
               inset 3px 3px 6px #3f3e3e45; */
    cursor: pointer; /* Indicate it's clickable */
    outline: none;
    margin-bottom: 2.5em;
  }
  
  .neu-form-button:hover,
  .neu-form-button:active {
    box-shadow: inset 2px 2px 4px #1a1a1a45,
                inset -2px -2px 4px #3f3e3e45;
                /* -2px -2px 4px #1a1a1a45,
                2px 2px 4px #3f3e3e45; */
  }
  
  .neu-form-button:hover {
    opacity: 0.8; /* A subtle hover effect */
  }

  select:invalid,
  select option[value=""] {
    color: #999999;
  }
  
  input[type="date"] { 
    color: #999999;
  }

  .profile-info-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.profile-information {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ai-profile-creator-button {
    display: block;
    margin: 20px auto;
    padding: 10px 15px;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;

    background-color: var(--button);
    backdrop-filter: blur(12px);
    box-shadow: -2px -2px 4px var(--shadow-dark-light), 2px 2px 4px var(--shadow-dark-dark);
}

.ai-profile-creator-button:hover {
    background-color: #0066cc;
}
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    .neu-form {
      padding: 20px;
    }
  
    .neu-form-input,
    .neu-form-select,
    .neu-form-button {
      padding: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .neu-form {
      width: calc(100% - 40px); /* Fill screen minus some padding */
      margin: 20px;
    }
  
    .neu-form-group {
      margin: 10px 0;
    }
  }