/* PinInputPopup.css */
.popup-overlay {
    font-size: 20px;
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* backdrop-filter: blur(5px); */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    /* background: #80838899; */
    /* backdrop-filter: blur(5px); */
    background: var(--background-primary-color);
    color: #e1e1e1;
    backdrop-filter: blur(50px);
    padding: 20px;
    border-radius: 10px;
    box-shadow:  6px 6px 12px #2a2c2e73,
              -6px -6px 12px #5a5c6273;
    align-items: center;
    transition: all 0.3s ease;
  }
  
  .pin-inputs {
    margin: 15px 0;
    display: flex;
    gap: 10px;
  }
  
  .pin-input {
    font-size: 20px;
    background: #c4c6ca99;
    width: 40px;
    text-align: center;
  }

  .popup button {
    margin: 8px;
  }
  
  .error-message {
    color: rgb(119, 0, 0);
  }
  