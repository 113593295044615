.account-container {
    color: white;
    border-radius: 10px;
    z-index: 1;
    margin: auto;
    max-width: 600px;
    padding: 20px;
    text-shadow: 0px 0px 5px #1e1e1e;
}

.account-plate {
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    z-index: 1;
    padding: 30px;
    margin: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.user-info {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 15px;
}

.profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pin-change-form, .subscription-change-form {
    grid-column: 1 / -1;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 15px;
}

input {
    display: block;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.1);
    color: white;
    width: calc(100% - 22px);
}

.neu-form-button {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    text-shadow: 0px 0px 5px #1e1e1e;
}

.neu-form-button:hover {
    background: rgba(255, 255, 255, 0.3);
}

.neu-form-button-small {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.9em;
    margin: 5px;
    text-shadow: 0px 0px 5px #1e1e1e;
}

.neu-form-button-small:hover {
    background: rgba(255, 255, 255, 0.3);
}

hr {
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin: 20px 0;
    grid-column: 1 / -1;
}

#delete-btn {
    background: rgba(220, 53, 69, 0.6);
    grid-column: 1 / -1;
}

#delete-btn:hover {
    background: rgba(220, 53, 69, 0.8);
}