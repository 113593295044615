.login-card {
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 5px;
    box-shadow: -2px -2px 4px  var(--shadow-dark-light), 2px 2px 4px var(--shadow-dark-dark);
    background: var(--background-primary-color);
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-shadow: 0px 0px 4px var(--shadow-dark-dark);
}

.login-card h {
    margin: 10px;
    font-size: 2em;
    text-align: center;
}

.login-card p {
    margin: 10px;
    font-size: 1.25em;
    text-align: center;
}

.userProfile {
    height: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    margin: 10px;
}

