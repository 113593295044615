.book-cover-icons {
  position: absolute;
  top: 1%;
  right: 0;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center;     */
  flex-wrap: wrap;

}

.cover-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
  /* margin-top: 1em; */
  /* margin-bottom: 1em; */
  opacity: 0.8;
  padding: 5px;
}

.icon-svg {
  width: 22px;
  height: 22px;

  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.9));
  /* margin-right: 7px; */
}

.icon-svg svg {
  width: 100%;
  height: 100%;
}

.icon-text {
  color: #fff;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.9));
  /* display: flex; */
  /* align-items: center; */
  /* padding: 0 5px 0px 5px; */
}