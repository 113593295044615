.report-problem-popover {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-primary-color);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 15px;
  box-shadow: -2px -2px 4px var(--shadow-dark-light), 2px 2px 4px var(--shadow-dark-dark);
  z-index: 1000;
  width: 90%;
  max-width: 600px;
}

.report-problem-popover-title {
    color: var(--text-primary-color);
    filter: drop-shadow(0px 0px 5px #000);
}

.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-problem-popover form {
  display: flex;
  flex-direction: column;
}

.report-problem-popover textarea {
  min-height: 100px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  filter: drop-shadow(0px 0px 5px #000);

  /* border: 1px solid rgba(255, 255, 255, 0.2); */
  border: none;
  border-radius: 10px;
  color: var(--text-primary-color);
  padding: 10px;
  font-size: 18px;
  backdrop-filter: blur(20px);
  transition: all 0.3s ease;
  ::placeholder {
    color: white;
  }
}

.report-problem-popover textarea::placeholder {
  color: rgb(223, 222, 222);
}



.report-problem-popover textarea:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.15);
}

.report-problem-popover .neu-button {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
